import { Button, TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import Grid from "@mui/material/Grid";
import { getOtp } from "../../utils/utils";

function Number() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [mbNumber, setMbNumber] = React.useState("");
  const [isErr, setIsErr] = React.useState(false);

  const handleNumber = () => {
    setIsErr(false);
    const postData = {
      number: mbNumber,
    };
    setLoading(true);
    axios
      .post(getOtp, postData)
      .then((res) => {
        if (res.data.error) {
          setIsErr(true);
        } else {
          localStorage.setItem("mbNumber", mbNumber);
          navigate("/otp");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="p_login">
      <div className="log_col1">
        <img
          src={window.location.origin + "/images/logopurple.svg"}
          alt="login"
          className="logo_css_purp"
        />
        <img
          src={window.location.origin + "/images/logo.svg"}
          alt="login"
          className="login_bg"
          data-aos="fade-right"
          data-aos-duration="600"
        />
      </div>
      <div className="log_col22" style={{ alignItems: "center" }}>
        <h1 className="log_name">Onboarding Dashboard</h1>

        <Grid container direction={"column"} spacing={2} alignItems="center">
          <Grid item>
            <div style={{ marginBottom: "10px" }}>
              <label id="mobile_num" style={{ fontFamily: "Nunito" }}>
                Enter Mobile Number
              </label>
            </div>
            <TextField
              id="mobile_num"
              fullWidth
              size="small"
              type="number"
              style={{ width: "300px" }}
              error={isErr}
              helperText={isErr ? "Unauthorized Access" : ""}
              variant="outlined"
              name="merchant_number"
              inputProps={{
                inputmode: "numeric",
                pattern: "[0-9]*",
              }}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              onChange={(e) => setMbNumber(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={mbNumber?.length > 9 ? false : true}
              style={{
                fontFamily: "Nunito",
                width: "300px",
                background: mbNumber?.length > 9 ? "#663595" : "#dfdfdf",
                color: mbNumber?.length > 9 ? "#fff" : "#000",
                borderRadius: "5px",
              }}
              onClick={handleNumber}
              fullWidth
              variant="contained"
            >
              Get Otp
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Number;
