export const api = {
  app_URL: process.env.REACT_APP_BASE_URL_APP,
  COMMON_URL: process.env.REACT_APP_COMMON_BASE_URL + "common",
  FARM_URL: process.env.REACT_APP_FARM_BASE_URL + "common",
  FARM_URL_QC: process.env.REACT_APP_FARM_BASE_URL + "qc",
  PRJ_URL: process.env.REACT_APP_PRJ_BASE_URL + "common",
  OA_URL: process.env.REACT_APP_BASE_URL + "oa_vendor",
  VENDOR_URL: process.env.REACT_APP_BASE_URL + "vendor",
  VENDOR_WEB_SITE_URL: process.env.REACT_APP_BASE_URL + "taskmo",
  FINANCE_URL: process.env.REACT_APP_BASE_URL + "finance",
  AWS_URL: process.env.REACT_APP_BASE_URL + "aws",
  TASKMO_URL: process.env.REACT_APP_BASE_URL + "taskmo",
  BUSINESS_URL: process.env.REACT_APP_BUSINESS_URL,
  farming_URL: process.env.REACT_APP_BASE_URL_FARMING_URL,
  ADMIN_URL: process.env.REACT_APP_BASE_URL_ADMIN,
  BIZOPS_URL: process.env.REACT_APP_BASE_URL_BIZOPS,
  APP_URL: process.env.REACT_APP_BASE_URL_APP,
  SPAPP_URL: process.env.REACT_APP_BASE_URL_SPAPP,
};

// REACT_APP_BASE_URL_APP = https://app.backend.taskmo.co/
