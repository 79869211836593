import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography variant="subtitle1">{children}</Typography>
      )}
    </div>
  );
}

const IdVerification = ({ dataObj }) => {
  const [value, setValue] = React.useState(0);

  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body1">
            ID-Card Verification Front Side
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ width: "100%" }}>
            <Card
              variant="outlined"
              style={{
                border: "none",
                boxShadow: "none",
                borderRadius: "2px",
              }}
            >
              <CardContent>
                <Grid container direction="column-reverse" spacing={2}>
                  <Grid item>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Typography variant="caption">
                              Created At:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption">
                              {dataObj.created_at}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={12} alignItems={"center"}>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="overline">
                              Id front submitted
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid>
                              <Zoom>
                                <div
                                  role="img"
                                  style={{
                                    width: "300px",
                                    height: "200px",
                                    border: "1px solid black",
                                    backgroundImage:
                                      "url(" + dataObj.aadhar_front_image + ")",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                              </Zoom>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <Grid
                              container
                              justifyContent={"space-between"}
                              alignItems="center"
                            >
                              <Grid item>
                                {" "}
                                <Typography variant="overline">
                                  verification result
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Button variant="contained">Edit</Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <div
                              style={{
                                border: "1px solid #EBEBEB",
                                borderRadius: "5px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "500px",
                                  padding: "12px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div>
                                  <Typography variant="overline">
                                    Aadhaar
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="caption">
                                    {dataObj.aadhar_number}
                                  </Typography>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "500px",
                                  padding: "12px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div>
                                  <Typography variant="overline">
                                    Name
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="caption">
                                    {dataObj.full_name}
                                  </Typography>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "500px",
                                  padding: "12px",
                                  borderRadius: "5px",
                                }}
                              >
                                <div>
                                  <Typography variant="overline">
                                    Date Of Birth
                                  </Typography>
                                </div>
                                <div>
                                  <Typography variant="caption">
                                    {dataObj.dob}
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* </TabPanel> */}
          </Box>
        </Grid>
      </Grid>
      <Grid></Grid>
    </div>
  );
};

export default IdVerification;
