import { api } from '../globalConfig';

const dashboardSearch = api.app_URL + 'onboard-dashboard/dashboard';

const apporveKyc = api.app_URL + 'onboard-dashboard/approveKyc';

const resetKyc = api.app_URL + 'onboard-dashboard/resetKyc';

const list = api.app_URL + 'onboard-dashboard/list';
// const list = 'http://localhost:3007/onboard-dashboard/list';

const getOtp = api.app_URL + 'login/onboard-dashboard/getOtp';

const verityOtp = api.app_URL + 'login/onboard-dashboard/verifyOtp';

const qcSubmit = api.app_URL + 'qctoken/qcSubmit';

const localUrl = 'onboard-dashboard/list';

const updateProjectType = api.app_URL + 'onboard-dashboard/updateProjectType';
const restoreAppAccount =
  api.app_URL + 'onboard-dashboard/updateRestoreAccount';

// for local

// const dashboardSearch = api.app_URL + "/onboard-dashboard/dashboard";

// const apporveKyc = api.app_URL + "/onboard-dashboard/approveKyc";

// const resetKyc = api.app_URL + "/onboard-dashboard/resetKyc";

// const list = api.app_URL + "/onboard-dashboard/list";
// // const list = 'http://localhost:3007/onboard-dashboard/list';

// const getOtp = api.app_URL + "/login/onboard-dashboard/getOtp";

// const verityOtp = api.app_URL + "/login/onboard-dashboard/verifyOtp";

// const qcSubmit = api.app_URL + "/qctoken/qcSubmit";

// const localUrl = "/onboard-dashboard/list";

// const updateProjectType = api.app_URL + "/onboard-dashboard/updateProjectType";

export {
  dashboardSearch,
  apporveKyc,
  resetKyc,
  list,
  getOtp,
  verityOtp,
  qcSubmit,
  localUrl,
  updateProjectType,
  restoreAppAccount,
};
