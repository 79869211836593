import { Button, TextField } from "@mui/material";
import React, { useState, useContext } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import Grid from "@mui/material/Grid";
import OtpInput from "react-otp-input";
import { verityOtp } from "../../utils/utils";

function Otp() {
  const navigate = useNavigate();

  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);

  const [error, setError] = React.useState(false);
  const mobile = localStorage.getItem("mbNumber");
  const handleChange = (code) => setOtp(code);

  const handleNumber = () => {
    setError(false);
    const postData = {
      number: mobile,
      otp,
    };
    axios
      .post(verityOtp, postData)
      .then((res) => {
        if (res.data.error) {
          setError(true);
        } else {
          navigate("/dashboard", { state: res.data.user_info });
          localStorage.setItem("name", res.data.user_info.full_name);
          localStorage.setItem("mbnumlog", "accepted");
          localStorage.setItem("token", res.data.user_info.token);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="p_login">
      <div className="log_col1">
        <img
          src={window.location.origin + "/images/logopurple.svg"}
          alt="login"
          className="logo_css_purp"
        />
        <img
          src={window.location.origin + "/images/logo.svg"}
          alt="login"
          className="login_bg"
          data-aos="fade-right"
          data-aos-duration="600"
        />
      </div>
      <div className="log_col22" style={{ alignItems: "center" }}>
        <h1 className="log_name">Onboarding Dashboard</h1>

        <Grid container direction={"column"} spacing={4} alignItems="center">
          <Grid item style={{ fontFamily: "Nunito" }}>
            {" "}
            We are sending you an OTP to verify your phone number
          </Grid>
          <Grid item>
            <OtpInput
              numInputs={4}
              onChange={handleChange}
              value={otp}
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 10px",
                fontSize: "16px",
                borderRadius: 4,
                border: error ? "1px solid red" : "1px solid rgba(0,0,0,0.3)",
                color: "black",
              }}
            />
          </Grid>
          <Grid item>
            <div style={{ color: "red" }}>
              {error && (
                <div style={{ fontFamily: "Nunito" }}>OTP is Invalid</div>
              )}
            </div>
          </Grid>

          <Grid item>
            <Button
              disabled={otp?.length == 4 ? false : true}
              style={{
                fontFamily: "Nunito",
                width: "300px",
                background:
                  otp?.length == 4 && !loading ? "#663595" : "#dfdfdf",
                color: otp?.length == 4 && !loading ? "#fff" : "#000",
                borderRadius: "5px",
              }}
              onClick={handleNumber}
              fullWidth
              variant="contained"
            >
              Verify Otp
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Otp;
