import React from "react";
import { Route, Routes } from "react-router-dom";
import Number from "../Login/Number";
import Otp from "../Login/Otp";
import { Dashboard } from "../pages/Dashboard";
import Images from "../pages/Images";
import { localRoute } from "./localRoutes";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { PrivateRoute2 } from "../PrivateRoute/PrivateRoute2";

const RouterComponent = () => {
  return (
    <div>
      <Routes>
        <Route path={localRoute.dashboard} element={<PrivateRoute />}>
          <Route path={localRoute.dashboard} element={<Dashboard />} />
        </Route>
        <Route exact path={localRoute.details} element={<PrivateRoute />}>
          <Route exact path={localRoute.details} element={<Images />} />
        </Route>

        <Route path={localRoute.notFound} element={<div>page not found</div>} />
        <Route path="/login" element={<PrivateRoute2 />}>
          <Route path="/login" element={<Number />} />
        </Route>
        <Route path="/otp" element={<PrivateRoute2 />}>
          <Route path="/otp" element={<Otp />} />
        </Route>
      </Routes>
    </div>
  );
};

export default RouterComponent;
