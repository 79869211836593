import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import React from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axios from "axios";
import { apporveKyc, resetKyc } from "../../utils/utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography variant="subtitle1">{children}</Typography>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SelfieMatchWithId = ({ dataObj }) => {
  const [value, setValue] = React.useState(0);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const [show1, setShow1] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const [openNotify, setOpenNotify] = React.useState(false);
  const [openNotify2, setOpenNotify2] = React.useState(false);

  const handleOpen2 = () => {
    setShow2(true);
  };

  const handleClose2 = () => {
    setShow2(false);
  };

  const handleClickNotify = () => {
    setOpenNotify(true);
  };

  const handleCloseNotify = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotify(false);
  };

  const handleClickNotify2 = () => {
    setOpenNotify2(true);
  };

  const handleCloseNotify2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotify2(false);
  };

  const handleOpen1 = () => {
    setShow1(true);
  };

  const handleClose1 = () => {
    setShow1(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleApproveKyc = () => {
    const putData = {
      user_id: dataObj.user_id,
    };
    axios
      .put(apporveKyc, putData)
      .then((res) => {
        handleClickNotify();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const handleResetkyc = () => {
    const putData = {
      user_id: dataObj.user_id,
    };
    axios
      .put(resetKyc, putData)
      .then((res) => {
        handleClickNotify2();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="body1">Selfie Match With ID</Typography>
        </Grid>
        <Grid item>
          <Box sx={{ width: "100%" }}>
            <Card
              variant="outlined"
              style={{
                border: "none",
                boxShadow: "none",
                borderRadius: "2px",
              }}
            >
              <CardContent>
                <Grid container direction="column-reverse" spacing={2}>
                  <Grid item>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Typography variant="caption">
                              Created At:
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption">
                              {dataObj.created_at}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={4} alignItems={"center"}>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="overline">
                              selfie submitted
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid>
                              <Zoom>
                                <div
                                  role="img"
                                  style={{
                                    paddingBottom: "10px",
                                    width: "300px",
                                    height: "200px",
                                    border: "1px solid black",
                                    backgroundImage:
                                      "url(" + dataObj.profile_image + ")",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                              </Zoom>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="overline">
                              ID submitted
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Grid>
                              <Zoom>
                                <div
                                  role="img"
                                  style={{
                                    width: "300px",
                                    height: "210px",
                                    border: "1px solid black",
                                    backgroundImage:
                                      "url(" + dataObj.aadhar_front_image + ")",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                              </Zoom>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {/* </TabPanel> */}

            <Grid container justifyContent={"flex-end"}>
              <Grid item>
                {dataObj.documentation_status == "needs_review" ? (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={handleOpen2}
                        style={{ width: "100px" }}
                        color="error"
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        style={{ width: "100px" }}
                        variant="contained"
                        onClick={handleOpen1}
                        color="success"
                      >
                        Approve
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                {dataObj.documentation_status == "rejected" ? (
                  <Button
                    variant="contained"
                    onClick={handleOpen2}
                    color="error"
                  >
                    Reset
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* for approve */}
      {show1 && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={show1}
          onClose={handleClose1}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Onboarding Approval"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Approve the Data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleApproveKyc();
                handleClose1();
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose1} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openNotify}
        autoHideDuration={6000}
        onClose={handleCloseNotify}
      >
        <Alert
          onClose={handleCloseNotify}
          severity="success"
          sx={{ width: "100%" }}
        >
          The Data is Approved!
        </Alert>
      </Snackbar>

      {/* for reset */}
      {show2 && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={show2}
          onClose={handleClose2}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Reset"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Reset the Data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleResetkyc();
                handleClose2();
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose2} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openNotify2}
        autoHideDuration={6000}
        onClose={handleCloseNotify2}
      >
        <Alert
          onClose={handleCloseNotify2}
          severity="error"
          sx={{ width: "100%" }}
        >
          The Data is Reset.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SelfieMatchWithId;
