import React from "react";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import EditDetails from "./EditDetails";
import Box from "@mui/material/Box";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import axios from "axios";
import { apporveKyc, resetKyc } from "../../utils/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typography variant="subtitle1">{children}</Typography>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SelfieVerification = ({ dataObj, mobileN }) => {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const [show1, setShow1] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const [openNotify, setOpenNotify] = React.useState(false);
  const [openNotify2, setOpenNotify2] = React.useState(false);

  const handleOpen2 = () => {
    setShow2(true);
  };

  const handleClose2 = () => {
    setShow2(false);
  };

  const handleClickNotify = () => {
    setOpenNotify(true);
  };

  const handleCloseNotify = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotify(false);
  };

  const handleClickNotify2 = () => {
    setOpenNotify2(true);
  };

  const handleCloseNotify2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotify2(false);
  };

  const handleOpen1 = () => {
    setShow1(true);
  };

  const handleClose1 = () => {
    setShow1(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenEditDetails = () => {
    handleOpen();
  };

  const handleApproveKyc = () => {
    const putData = {
      user_id: dataObj.user_id,
    };
    axios
      .put(apporveKyc, putData)
      .then((res) => {
        handleClickNotify();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const handleResetkyc = () => {
    const putData = {
      user_id: dataObj.user_id,
    };
    axios
      .put(resetKyc, putData)
      .then((res) => {
        handleClickNotify2();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Grid container direction="column">
        <Grid item style={{ marginBottom: "40px" }}>
          <Grid
            container
            justifyContent={"space-between"}
            style={{
              background: "#F7F7F7",
              padding: "20px",
              borderRadius: "12px",
            }}
            alignItems="center"
          >
            <Grid item>
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item>User Id: </Grid>
                <Grid item style={{ fontWeight: "600" }}>
                  {dataObj.user_id}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {" "}
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item>Name: </Grid>
                <Grid item style={{ fontWeight: "600" }}>
                  {dataObj.full_name}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {" "}
              <Grid container spacing={1} alignItems={"center"}>
                <Grid item>Mobile Number:&nbsp;{mobileN}</Grid>
                <Grid item style={{ fontWeight: "600" }}>
                  {dataObj.mobile_number}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {" "}
              <Grid container alignItems={"center"} spacing={1}>
                <Grid item>Status:</Grid>
                <Grid item style={{ fontWeight: "600" }}>
                  {dataObj.documentation_status == "verified" ? (
                    <Chip
                      label="verified"
                      style={{
                        background: "#14a44d",
                        color: "white",
                        width: "120px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {dataObj.documentation_status == "needs_review" ? (
                    <Chip
                      label="needs_review"
                      style={{
                        color: "white",
                        background: "#e4a11b",
                        width: "120px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {dataObj.documentation_status == "duplicate" ? (
                    <Chip
                      style={{
                        background: "red",
                        color: "white",
                        width: "120px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                      label="duplicate"
                    />
                  ) : (
                    ""
                  )}
                  {dataObj.documentation_status == "rejected" ? (
                    <Chip
                      label="rejected"
                      style={{
                        background: "#9fa6b2",
                        color: "#fff",
                        width: "120px",
                        height: "30px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {dataObj.documentation_status == "duplicate" && (
          <>
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Grid item>
                  <Typography variant="body1">Selfie Verification</Typography>
                </Grid>
                <Grid item>
                  {true ? (
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={handleOpen2}
                          color="error"
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                  {dataObj.documentation_status == "rejected" ? (
                    <Button
                      variant="contained"
                      // onClick={handleResetkyc}
                      onClick={handleOpen2}
                      color="error"
                    >
                      Reset
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box sx={{ width: "100%" }}>
                <Card
                  variant="outlined"
                  style={{
                    border: "none",
                    boxShadow: "none",
                    borderRadius: "2px",
                  }}
                >
                  <CardContent>
                    <Grid container direction="column-reverse" spacing={2}>
                      <Grid item>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Grid container spacing={1}>
                              <Grid item>
                                <Typography variant="caption">
                                  Created At:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="caption">
                                  {dataObj.created_at}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item></Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Grid container direction="column">
                              <Grid item>
                                <Typography variant="overline">
                                  selfie submitted
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Grid>
                                  <Zoom>
                                    <div
                                      role="img"
                                      style={{
                                        width: "300px",
                                        height: "200px",
                                        border: "1px solid black",

                                        backgroundImage:
                                          "url(" + dataObj.profile_image + ")",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                      }}
                                    ></div>
                                  </Zoom>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                {/* </TabPanel> */}
              </Box>
            </Grid>
          </>
        )}
        {dataObj.documentation_status == "duplicate" && (
          <Grid item style={{ paddingBottom: "100px" }}>
            <Grid container justifyContent={"center"} alignItems={"center"}>
              <Typography style={{ color: "red", paddingTop: "50px" }}>
                Already aadhar is assigned with another number Please Check!
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/* for approve */}
      {show1 && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={show1}
          onClose={handleClose1}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Onboarding Approval"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Approve the Data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleApproveKyc();
                handleClose1();
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose1} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openNotify}
        autoHideDuration={6000}
        onClose={handleCloseNotify}
      >
        <Alert
          onClose={handleCloseNotify}
          severity="success"
          sx={{ width: "100%" }}
        >
          The Data is Approved!
        </Alert>
      </Snackbar>

      {/* for reset */}
      {show2 && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={show2}
          onClose={handleClose2}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Reset"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Reset the Data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleResetkyc();
                handleClose2();
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose2} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openNotify2}
        autoHideDuration={6000}
        onClose={handleCloseNotify2}
      >
        <Alert
          onClose={handleCloseNotify2}
          severity="error"
          sx={{ width: "100%" }}
        >
          The Data is Reset.
        </Alert>
      </Snackbar>

      <EditDetails
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      />
    </div>
  );
};

export default SelfieVerification;
