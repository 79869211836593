import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import React from "react";

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const EditDetails = ({ open, onClose, fullWidth, maxWidth }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        PaperProps={{
          sx: {
            minHeight: 650,
          },
        }}
      >
        <div style={{padding:'20px'}}>
          <Grid>
            <Grid container justifyContent={'space-between'} alignItems="center">
              <Grid item><Typography variant='h5'>Edit Details</Typography></Grid>
              <Grid item><ExitToAppIcon/></Grid>
            </Grid>
            <Divider/>
            <form>
            <Grid container direction={'column'} spacing={2} sx={{marginTop:'10px'}}>
               
                <Grid item>
                    <TextField  fullWidth label='Full Name'/>
                </Grid>
                <Grid item>
                    <TextField  fullWidth label='Aadhaar Number'/>
                </Grid>
                <Grid item>
                    <TextField  fullWidth label='Selfie Image URL'/>
                </Grid>
                <Grid item>
                    <TextField  fullWidth label='Aadhaar Font Image URL'/>
                </Grid>
                <Grid item>
                    <TextField  fullWidth label= 'Aadhaar Back Image URL'/>
                </Grid>
                <Grid item sx={{display:'flex',justifyContent:'end'}}>
                    <Button variant='contained'>Submit</Button>
                </Grid>
            </Grid>
            </form>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

export default EditDetails;
