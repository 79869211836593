import { Card, CardContent, Grid } from "@mui/material";
import React, { useContext } from "react";
import "../css/Images.css";
import Typography from "@mui/material/Typography";
import SelfieVerification from "./SelfieVerification";
import IdVerification from "./IdVerification";
import IdVerificationBack from "./IdVerificationBack";
import SelfieMatchWithId from "./SelfieMatchWithId";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography variant="caption">{children}</Typography>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Images = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const { state } = useLocation();

  let dataObj = state?.state1;
  let mobileN = state?.mbNumber;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  document.body.style.background = "#F6F7FF";
  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: "42px" }}
      flexDirection={"row-reverse"}
    >
      <Navbar />
      <Grid item style={{ marginTop: "50px", float: "left" }}>
        <Grid container>
          <Grid item>
            <Grid container alignItems={"center"} spacing={2}>
              <Grid item style={{ cursor: "pointer" }}>
                {" "}
                <ArrowBackIcon onClick={() => navigate(-1)} />
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: "20px" }}>Back</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ width: "100%" }}>
        <Grid container direction="column" spacing={3}>
          {
            <Grid item>
              <Card
                variant="outlined"
                style={{
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "2px",
                  // width: "75%",
                }}
              >
                <CardContent>
                  <div id="selfieverification">
                    <SelfieVerification dataObj={dataObj} mobileN={mobileN} />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          }

          {dataObj.documentation_status != "duplicate" && (
            <Grid item>
              <Card
                variant="outlined"
                style={{
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "2px",
                  // width: "75%",
                }}
              >
                <CardContent>
                  <Grid container direction="column">
                    <Grid item>
                      <div id="idverify">
                        {dataObj.documentation_status != "duplicate" && (
                          <IdVerification dataObj={dataObj} />
                        )}
                      </div>
                    </Grid>
                    <Grid>
                      <div>
                        {dataObj.documentation_status != "duplicate" && (
                          <IdVerificationBack dataObj={dataObj} />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          {dataObj.documentation_status != "duplicate" && (
            <Grid item>
              <Card
                variant="outlined"
                style={{
                  border: "none",
                  boxShadow: "none",
                  borderRadius: "2px",
                }}
              >
                <CardContent>
                  <div id="selfieverificationidMatch">
                    {dataObj.documentation_status != "duplicate" && (
                      <SelfieMatchWithId dataObj={dataObj} />
                    )}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Images;
