import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import ProfileCard from "./ProfileCard";

export default function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show2, setShow2] = React.useState(false);

  const open = Boolean(anchorEl);
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("mbNumber");
    localStorage.removeItem("token");
    localStorage.removeItem("mbnumlog");
    window.location.reload();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          background: "#fff",
          boxShadow:
            " rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img src="/taskmologo1.svg" alt="" height="34px" />
          </Typography>

          <div style={{ marginRight: "16px" }}>
            <Avatar
              sx={{ bgcolor: deepOrange[500] }}
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              {localStorage.getItem("name")[0]}
            </Avatar>
          </div>

          <Menu
            style={{ marginTop: "40px" }}
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                handleLogout();
              }}
            >
              logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Modal
        open={show2}
        onClose={() => setShow2(false)}
        className="model_contain"
      >
        <ProfileCard setShow={setShow2} />
      </Modal>
    </Box>
  );
}
