import logo from "./logo.svg";
import "./App.css";
import RouterComponent from "./components/routes/RouterComponent";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
  }, []);
  return (
    <div className="App">
      <RouterComponent />
    </div>
  );
}

export default App;
