import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import { useNavigate } from 'react-router-dom';
import '../css/Dashboard.css';
import axios from 'axios';
import {
  dashboardSearch,
  list,
  resetKyc,
  restoreAppAccount,
  updateProjectType,
} from '../../utils/utils';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import Navbar from './Navbar';
import MuiAlert from '@mui/material/Alert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { utils, writeFile } from 'xlsx';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

document.body.style.background = '#F6F7FF';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Dashboard = () => {
  const navigate = useNavigate();

  const currentDate = moment();

  const firstDayOfMonth = moment(currentDate).startOf('month');
  const currentDayOfMonth = moment(currentDate).date();

  // Formatted date"
  const formattedFirstDay = firstDayOfMonth.format('YYYY-MM-DD');
  const formattedCurrentDate = currentDate.format('YYYY-MM-DD');

  const [data, setData] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xs');
  const [open, setOpen] = React.useState(false);
  const [useridd, setUseridd] = React.useState('');
  const [loader2, setIsLoading2] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openNotify2, setOpenNotify2] = React.useState(false);
  const [openRestore, setOpenRestore] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const [searchData, setSearchData] = React.useState('');
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [range, setRange] = React.useState(true);
  const [dateRange, setDateRange] = React.useState([
    formattedFirstDay,
    formattedCurrentDate,
  ]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const [startDate, endDate] = selectedDates;
      setDateRange([
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
      ]);
      setRange(!range);
    } else {
      setDateRange([]);
    }
  };

  const obj = {};
  const cardMap = [
    {
      label: 'Total Leads',
      labelClass: 'muted',
      counter: obj?.total_leads,
      decimals: 0,
      separator: ',',
      suffix: '',
      icon: 'mdi mdi-briefcase-check-outline',
      iconClass: 'primary',
      percentage: `+ ${obj?.total_leads_today}`,
      percentageClass: 'primary',
      prefix: '',
    },
    {
      label: 'Approved Leads',
      labelClass: 'muted',
      counter: obj?.approved_leads,

      icon: 'ri ri-thumb-up-line',
      iconClass: 'success',
      percentage: `+ ${obj?.approved_leads_today}`,
      percentageClass: 'success',
      decimals: 0,
      separator: ',',
      suffix: '',
      prefix: '',
    },
    {
      icon: 'mdi mdi-briefcase-clock-outline pending-icon',
      iconClass: 'warning',
      label: 'QC-Pending Leads',
      labelClass: 'muted',
      counter: obj?.pending_leads,
      decimals: 0,
      percentage: `+ ${obj?.pending_leads_today}`,
      percentageClass: 'warning',
      separator: ',',
      suffix: '',
      prefix: '',
    },
  ];

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const openFilter = Boolean(anchorEl);
  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleCloseRestore = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenRestore(false);
  };

  //for reset rejected

  const handleOpen2 = () => {
    setShow2(true);
  };

  const handleClose2 = () => {
    setShow2(false);
  };

  const handleClickNotify2 = () => {
    setOpenNotify2(true);
  };

  const handleCloseNotify2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotify2(false);
  };

  const handleOpenLeads = (d) => {
    if (d.documentation_status == 'rejected') {
    } else {
      axios
        .get(dashboardSearch, { params: { mobile_number: d.mobile_number } })
        .then((res) => {
          if (
            res.data.message == 'success' &&
            res.data.userDetails.length > 0
          ) {
            navigate('/onboarding-details', {
              state: {
                state1: res.data.userDetails[0],
                mbNumber: d.mobile_number,
              },
            });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleclickSearch = () => {
    if (searchData.length < 10) return '';
    axios
      .get(dashboardSearch, { params: { mobile_number: searchData } })
      .then((res) => {
        if (res.data.message == 'success' && res.data.userDetails.length > 0) {
          navigate('/onboarding-details', {
            state: {
              state1: res.data.userDetails[0],
              mbNumber: searchData,
            },
          });
        }
        if (res.data.userDetails.length == 0) {
          handleClick();
          setTimeout(() => {
            handleClose();
          }, 2000);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (searchData.length < 10) return '';
      axios
        .get(dashboardSearch, { params: { mobile_number: searchData } })
        .then((res) => {
          if (
            res.data.message == 'success' &&
            res.data.userDetails.length > 0
          ) {
            navigate('/onboarding-details', {
              state: {
                state1: res.data.userDetails[0],
                mbNumber: searchData,
              },
            });
          }
          if (res.data.userDetails.length == 0) {
            handleClick();
            setTimeout(() => {
              handleClose();
            }, 2000);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const updateProject = (val, user_id) => {
    let url = updateProjectType;
    let body = {
      project_type: val,
      user_id,
    };

    setIsLoading2(true);
    axios
      .post(url, body)
      .then((res) => {
        onboardData();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading2(false);
      });
  };

  const handleRestore = (user_id) => {
    setOpenRestore(true);
    let url = restoreAppAccount;
    let body = {
      user_id,
    };
    setIsLoading2(true);
    axios
      .post(url, body)
      .then((res) => {
        onboardData();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading2(false);
      });
  };

  const handleRangeChange = (e) => {
    setRange(e.target.value);
  };

  const revisedData = filterData.map((item) => {
    const { id, ...rest } = item;
    return { id, ...rest };
  });

  const exportToExcel = () => {
    const ws = utils.json_to_sheet(revisedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, 'data.xlsx');
  };

  const check = () => {
    setIsLoading(true);
    axios
      .post(list, { month, year })
      .then((res) => {
        setIsLoading(false);
        if (!res.data.error) {
          let mappedOutput = res.data.userList.map((item, index) => ({
            ...item,
            id: index,
          }));
          setData(mappedOutput);
          setFilterData(mappedOutput);
        }
      })
      .catch((err) => setIsError(true))
      .finally(() => setIsLoading(false));
  };

  const onboardData = () => {
    const body = {
      from: dateRange[0],
      to: dateRange[1],
    };

    setIsLoading(true);
    axios
      .post(list, body)
      .then((res) => {
        if (!res.data.error) {
          let mappedOutput = res.data.userList.map((item, index) => ({
            ...item,
            id: index,
          }));
          setData(mappedOutput);
          setFilterData(mappedOutput);
        }
      })
      .catch((err) => setIsError(true))
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    // check();
    onboardData();
  }, [range]);

  const columns = [
    {
      name: 'User ID:',
      width: '100px',
      center: true,
      cell: (d) => d.user_id,

      style: {
        paddingLeft: '30px',
      },
    },
    {
      name: 'Full Name',
      selector: (row) => row.full_name,
      center: true,
      width: '250px',
    },

    {
      omit: 'true',
      name: 'email',
      selector: (row) => row.email_id,
      center: true,
      width: '250px',
    },
    {
      name: 'Mobile Number',
      selector: (row) => row.mobile_number,
      center: true,
    },
    {
      name: 'City',
      selector: (row) => row.city,
      center: true,
    },
    {
      name: 'Pincode',
      selector: (row) => row.pincode,
      center: true,
    },
    {
      name: 'Created on',
      selector: (row) => row.created_at,
      center: true,
      cell: (d) => <div>{d.created_at}</div>,
    },
    {
      name: 'Project Type',
      selector: (row) => row.project,
      center: true,
      cell: (d) => (
        <div>
          <select
            defaultValue={d.project}
            name="cars"
            id="cars"
            onChange={(e) => updateProject(e.target.value, d.user_id)}
          >
            <option value="FOT">Field</option>
            <option value="quess">Quess</option>
          </select>
        </div>
      ),
    },

    {
      name: 'Account status',
      width: '180px',
      selector: (row) => row.logout_status,
      center: true,
      cell: (d) => (
        <div>
          {d.logout_status == 'true' ? (
            <Button
              onClick={() => handleRestore(d.user_id)}
              style={{ textTransform: 'capitalize' }}
              variant="contained"
              color="error"
            >
              Restore
            </Button>
          ) : (
            <Button style={{ textTransform: 'capitalize' }} color="success">
              Live
            </Button>
          )}
        </div>
      ),
    },

    {
      name: 'Wallet status',
      selector: (row) => row.wallet_activation_status,
      center: true,
      cell: (d) => (
        <div>
          {d.wallet_activation_status == 1
            ? 'Activated'
            : d.wallet_activation_status == 'none'
            ? 'Pending'
            : 'Rejected'}
        </div>
      ),
    },

    {
      name: 'Status',
      selector: (row) => row.documentation_status,
      cell: (d) => (
        <div>
          {d.documentation_status == 'verified' ? (
            <Chip
              label="verified"
              style={{
                background: '#14a44d',
                color: 'white',
                width: '120px',
                height: '30px',
                cursor: 'pointer',
              }}
            />
          ) : (
            ''
          )}
          {d.documentation_status == 'needs_review' ? (
            <Chip
              label="needs_review"
              style={{
                color: 'white',
                background: '#e4a11b',
                width: '120px',
                height: '30px',
                cursor: 'pointer',
              }}
            />
          ) : (
            ''
          )}
          {d.documentation_status == 'duplicate' ? (
            <Chip
              style={{
                background: 'red',
                color: 'white',
                width: '120px',
                height: '30px',
                cursor: 'pointer',
              }}
              label="duplicate"
            />
          ) : (
            ''
          )}
          {d.documentation_status == 'rejected' ? (
            <Chip
              label="rejected"
              style={{
                background: '#9fa6b2',
                color: '#fff',
                width: '120px',
                height: '30px',
                cursor: 'pointer',
              }}
            />
          ) : (
            ''
          )}
        </div>
      ),
      center: true,
    },
    {
      name: 'Reset',
      // selector: (row) => row.created_at,
      center: true,
      cell: (d) => (
        <div
          onClick={() => {
            if (d.documentation_status == 'rejected') {
              setUseridd(d.user_id);
              handleOpen2();
            }
          }}
        >
          {d.documentation_status == 'rejected' ? (
            <img src={'/reset_dark_icon.svg'} alt="darkicon" />
          ) : (
            <img
              src={'/reset_grey_icon.svg'}
              alt="greyicon"
              style={{ cursor: 'default' }}
            />
          )}
        </div>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '30px',
        // paddingRight: "8px",
      },
    },
  };

  const handleResetkyc = () => {
    const putData = {
      user_id: useridd,
    };
    handleClickNotify2();
    axios
      .put(resetKyc, putData)
      .then((res) => {
        handleClickNotify2();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  const conditionalRowStyles = [
    {
      when: (row) => Number(row.id) % 2 === 0,
      style: {
        background: '#f7f7f7',
        border: 'none',
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    {
      when: (row) => Number(row.id) % 2 !== 0,
      style: {
        background: '#ffffff',
        border: 'none',
        color: 'black',
        cursor: 'pointer',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const tableData = {
    data: filterData,
    columns,
  };

  const handleFilterData = (status) => {
    if (status == 'all') {
      handleClose();
      setFilterData(data);
      return '';
    }

    let ot = data.filter((item) => item.documentation_status == status);
    ot = ot.map((item, i) => ({ ...item, id: i }));
    handleClose();

    setFilterData(ot);
  };

  return isLoading ? (
    <>
      <div style={{ position: 'fixed', left: '50%', top: '0' }}>
        {' '}
        <CircularProgress />
      </div>
    </>
  ) : isError ? (
    <>something went wrong</>
  ) : (
    <div>
      <Navbar />
      <div style={{ marginTop: '88px' }}>
        {/* <Row>
          <Col
            sm={3}
            className="d-flex gap-2 "
            style={{
              flexGrow: "1",
              paddingRight: "0px",
              paddingLeft: "20px",
            }}
          >
            {(props.cardMap || []).map((item, key) => (
              <Card
                className={"card-animate " + item.bgColor}
                style={{ width: "100%" }}
                key={key}
              >
                <CardBody>
                  <div className="d-flex align-items-center px-2">
                    <div className="avatar-sm flex-shrink-0">
                      <span
                        className={
                          "avatar-title fs-1 rounded-2  bg-soft-" +
                          item.iconClass +
                          " text-" +
                          item.iconClass
                        }
                      >
                        <i className={item.icon}></i>
                      </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p
                        className={
                          "text-uppercase fw-medium mb-1 text-" +
                          item.labelClass
                        }
                        style={{ fontSize: "10px" }}
                      >
                        {item.label}
                      </p>
                      <h4
                        className={" mb-1 " + item.counterClass}
                        style={{ fontSize: "13px" }}
                      >
                        <span className="counter-value">
                          <CountUp
                            start={0}
                            prefix={item.prefix}
                            suffix={item.suffix}
                            separator={item.separator}
                            end={item.counter}
                            decimals={item.decimals}
                            duration={0}
                          />
                        </span>
                      </h4>
                      <p className={"mb-0 text-" + item.captionClass}>
                        {item.caption}
                      </p>
                    </div>
                    <div className="flex-shrink-0 align-self-center">
                      <span
                        className={
                          "fs-9 badge badge-soft-" + item.percentageClass
                        }
                      >
                        <i
                          className={"fs-10 align-middle me-1 " + item.badge}
                        ></i>
                        {item.percentage}
                        <span></span>
                      </span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ))}
          </Col>
        </Row> */}
      </div>
      <div className="mainTable" style={{ marginTop: '18px' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              paddingLeft: '10px',
              fontSize: '22px',
              fontWeight: '600',
              alignItems: 'center',
            }}
          >
            Onboarding Verification
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              margin: ' 20px 0 20px 0',
              gap: '10px',
            }}
          >
            {/* <div>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                // label="Age"
                onChange={handleChangeMonth}
              >
                <MenuItem value={13}>ALL</MenuItem>
                <MenuItem value={1}>JAN</MenuItem>
                <MenuItem value={2}>FEB</MenuItem>
                <MenuItem value={3}>MAR</MenuItem>
                <MenuItem value={4}>APR</MenuItem>
                <MenuItem value={5}>MAY</MenuItem>
                <MenuItem value={6}>JUN</MenuItem>
                <MenuItem value={7}>JUL</MenuItem>
                <MenuItem value={8}>AUG</MenuItem>
                <MenuItem value={9}>SEP</MenuItem>
                <MenuItem value={10}>OCT</MenuItem>
                <MenuItem value={11}>NOV</MenuItem>
                <MenuItem value={12}>DEC</MenuItem>
              </Select>
            </div> */}
            {/* 
            <div>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                // label="Age"
                onChange={handleChangeYear}
              >
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2022}>2022</MenuItem>
                <MenuItem value={2021}>2021</MenuItem>
                <MenuItem value={2020}>2020</MenuItem>
              </Select>
            </div> */}

            <div>
              <Flatpickr
                className="form-control"
                options={{
                  mode: 'range',
                  dateFormat: 'Y-m-d',
                  maxDate: 'today',
                }}
                style={{
                  height: '33px',
                  borderRadius: '4px',
                  border: '1.6px solid #c4c4c4',
                  paddingLeft: '12px',
                  fontSize: '15px',
                  width: '186px',
                }}
                value={dateRange}
                placeholder={'Select Date'}
                // disabled={range == "range" ? false : true}
                onChange={handleDateChange}
              />
            </div>

            <Button
              variant="contained"
              onClick={exportToExcel}
              startIcon={<CloudDownloadIcon />}
            >
              Download Excel
            </Button>

            <div style={{ margin: '20px', marginLeft: '-0px' }}>
              <Button
                style={{ width: '131px' }}
                variant="contained"
                onClick={handleClickFilter}
                startIcon={<FilterAltIcon />}
              >
                Filter
              </Button>

              <Menu
                style={{
                  marginTop: '40px',
                }}
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={openFilter}
                onClose={handleCloseFilter}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {
                    left: '50%',
                    transform: 'translateX(-1%) translateY(1%)',
                    boxShadow:
                      'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleFilterData('all');
                    handleCloseFilter();
                  }}
                >
                  All
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleFilterData('needs_review');
                    handleCloseFilter();
                  }}
                >
                  Needs Review
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleFilterData('rejected');
                    handleCloseFilter();
                  }}
                >
                  Rejected
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleFilterData('duplicate');
                    handleCloseFilter();
                  }}
                >
                  Duplicate
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleFilterData('verified');
                    handleCloseFilter();
                  }}
                >
                  Verified
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>

        <DataTableExtensions
          {...tableData}
          export={false}
          filterPlaceholder={`Search`}
        >
          <DataTable
            // title='Lead Data'
            columns={columns}
            data={filterData}
            pagination
            fixedHeader
            fixedHeaderScrollHeight="65vh"
            selectableRowsHighlight
            highlightOnHover
            subHeader
            subHeaderAlign="left"
            conditionalRowStyles={conditionalRowStyles}
            onRowClicked={handleOpenLeads}
            customStyles={customStyles}
          />
        </DataTableExtensions>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          No data found!
        </Alert>
      </Snackbar>

      {show2 && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={show2}
          onClose={handleClose2}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{'Reset'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Reset the Data.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleResetkyc();
                handleClose2();
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose2} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openNotify2}
        autoHideDuration={6000}
        onClose={handleCloseNotify2}
      >
        <Alert
          onClose={handleCloseNotify2}
          severity="error"
          sx={{ width: '100%' }}
        >
          The Data is Reset.
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openRestore}
        autoHideDuration={6000}
        onClose={handleCloseRestore}
      >
        <Alert
          onClose={handleCloseRestore}
          severity="success"
          sx={{ width: '100%' }}
        >
          Restored Account Successfully
        </Alert>
      </Snackbar>
    </div>
  );
};

export { Dashboard };

